<template>
  <div>
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Assigning user...</p>
        </brightbid-loader>
      </div>
    </div>

    <div class="text-center text-bb-text-default m-10">
      <h3 class="h3">Assign to User</h3>
      <p>Assign users who will have access to the selected Organization and the respective Sites.</p>
    </div>
    <div class="my-10 overflow-y-auto max-h-100 base-scrollbar">
      <form class="mx-10 mb-60">
        <div class="space-y-6">
          <div>
            <multi-select-search-input
              v-model="assignUserForm.users"
              placeholder="Select user..."
              input-name="select-user"
              input-id="select-user"
              label="Select User"
              :is-loading="loadingUsers"
              :options="userOptions"
              :error-message="$v.assignUserForm.users.$error ? 'This field is required' : null"
              @update-chips="updateUserList"
            />
          </div>

          <div
            class="flex items-center gap-1 text-bb-brand-purple cursor-pointer"
            :class="showInviteUserField ? 'text-bb-disabled-gray' : 'text-bb-brand-purple'"
            @click="showInviteUserField = true"
          >
            <ic-plus :size="16" />
            <p class="font-medium">Invite new user</p>
          </div>

          <div v-if="showInviteUserField">
            <text-input
              v-model="assignUserForm.email"
              :disabled="isLoading"
              label="Email"
              input-name="email"
              input-id="email"
              input-type="email"
              placeholder="email@gmail.com"
              :error-message="emailErrorMessage"
              @focusout="$v.assignUserForm.email.$touch"
            />
          </div>

          <div class="space-y-6">
            <div>
              <h4 class="h4">Organizations</h4>
              <p class="text-sm text-bb-text-secondary">Choose one or more organizations to be managed by this user.</p>
            </div>
            <div class="grid grid-cols-2 items-center gap-6">
              <div
                v-for="(organization, index) in $v.assignUserForm.organizations.$each.$iter"
                :key="`${organization.$model.value}-${index}`"
              >
                <div class="w-87 flex items-center gap-1">
                  <search-input
                    v-model="organization.$model"
                    placeholder="Select Organization..."
                    :input-name="organization.$model.value"
                    :input-id="index"
                    :options="filteredOptions"
                    class="w-full"
                    :error-message="organization.$error ? 'Please select an organization' : null"
                    @blur="organization.$touch"
                    @select-item="updateOrganizationList"
                  />
                  <ic-cross
                    v-if="assignUserForm.organizations.length > 1"
                    :disabled="assignUserForm.organizations.length <= 1"
                    @close="removeOrganizationInput(index)"
                  />
                </div>
              </div>
              <div
                class="cursor-pointer"
                @click="addOrganizationInput"
              >
                <p
                  class="font-medium"
                  :class="hasAtLeastOneOrganization ? 'text-bb-brand-purple' : 'text-bb-disabled-gray'"
                >
                  + Add Organization
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--SAVE AND CANCEL BUTTONS-->
    <div class="flex justify-between items-center m-10">
      <merge-button-round
        :disabled="isLoading"
        button-type="secondary"
        class="w-40"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        :disabled="isLoading"
        button-type="primary"
        class="w-40"
        @click="save"
      >
        <p>Save</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import { email, helpers, required } from 'vuelidate/lib/validators'
import IcCross from '@/components/icon/ic-cross'
import TextInput from '@/components/input/brightbid/TextInput'
import SearchInput from '@/components/input/brightbid/SearchInput'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import MultiSelectSearchInput from '@/components/input/brightbid/MultiSelectSearchInput.vue'

const defaultOrganization = { value: null, label: null }

const chipsValidation = chips => {
  if (chips.length <= 0) return false
  return chips.every(chip => chip.value !== null && chip.label !== null)
}

export default {
  name: 'AssignUserModalForm',
  components: {
    TextInput,
    SearchInput,
    IcCross,
    BrightbidLoader,
    MergeButtonRound,
    MultiSelectSearchInput,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    defaultOrganization: {
      type: Object,
      default: () => defaultOrganization,
    },
    userOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showInviteUserField: false,
      loadingUsers: false,
      assignUserForm: {
        users: [],
        email: null,
        organizations: null,
      },
    }
  },
  computed: {
    filteredOptions() {
      return this.options.filter(item => !this.assignUserForm.organizations.some(org => org.value === item.value))
    },
    hasAtLeastOneOrganization() {
      const organizations = this.assignUserForm.organizations
      // Check if organizations array exists, has at least one item, and the first organization has a defined value
      return (
        organizations &&
        organizations.length > 0 &&
        organizations[0].value !== undefined &&
        organizations[0].value !== null
      )
    },
    emailErrorMessage() {
      if (!this.$v.assignUserForm.email.$dirty || !this.$v.assignUserForm.email.$model) return ''
      if (!this.$v.assignUserForm.email.email) return this.$v.assignUserForm.email.$params.email.errorMessage
      if (this.userOptions.some(userOption => userOption.value === this.assignUserForm.email))
        return 'This email is already associated with an existing user'
      return ''
    },
  },
  async mounted() {
    if (this.defaultOrganization !== defaultOrganization) {
      this.assignUserForm.organizations = [
        { label: this.defaultOrganization.organization, value: this.defaultOrganization.id },
      ]
    } else {
      this.assignUserForm.organizations = [this.defaultOrganization]
    }
  },
  validations() {
    return {
      assignUserForm: {
        users: {
          required,
          chipsValidation,
        },
        organizations: {
          required,
          $each: { label: { required }, value: { required } },
        },
        email: {
          email: helpers.withParams({ errorMessage: 'Please enter a valid email' }, email),
        },
      },
    }
  },
  methods: {
    addOrganizationInput() {
      if (!this.hasAtLeastOneOrganization) return
      const emptySearchInput = { value: '', label: '' }
      this.assignUserForm.organizations.push(emptySearchInput)
    },
    removeOrganizationInput(index) {
      if (this.assignUserForm.organizations.length === 1) return
      this.assignUserForm.organizations.splice(index, 1)
    },
    updateUserList(data) {
      this.assignUserForm.users = data
    },
    save() {
      this.$v.assignUserForm.$touch()
      if (!this.assignUserForm.email) {
        this.assignUserForm.email = null
        this.$v.assignUserForm.email.$reset()
      }
      if (this.$v.assignUserForm.$invalid) return

      this.$emit('save', this.assignUserForm)
    },
    updateOrganizationList(data, index) {
      this.assignUserForm.organizations.splice(index, 1, data)
    },
  },
}
</script>

<style scoped></style>
