var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('search-input',{staticClass:"w-full",attrs:{"dynamic-placeholder":"","disabled":_vm.disabled,"options-max-width":_vm.optionsMaxWidth,"error-message":_vm.errorMessage,"input-id":_vm.inputId,"input-name":_vm.inputName,"placeholder":_vm.placeholder,"options":_vm.options,"label":_vm.label,"sub-label":_vm.subLabel,"is-loading":_vm.isLoading,"grouped":_vm.grouped},on:{"select-item":_vm.appendToChipList},scopedSlots:_vm._u([{key:"label-icon",fn:function(){return [_vm._t("icon")]},proxy:true},{key:"label",fn:function({ label, inputId }){return [_c('div',{staticClass:"flex justify-between"},[_c('label',{staticClass:"font-medium",attrs:{"for":inputId}},[_vm._v(" "+_vm._s(label)+" ")]),(_vm.chips.length > 0)?_c('p',{staticClass:"font-medium text-bb-brand-purple text-sm cursor-pointer",on:{"click":_vm.clearAllChips}},[_vm._v(" Clear All ")]):_vm._e()])]}}],null,true)}),(_vm.chipVariant == 'squared')?_c('div',{staticClass:"my-2 flex flex-wrap items-center gap-2"},_vm._l((_vm.chips),function(chip,index){return _c('div',{key:`${chip}-${index}`,staticClass:"flex items-center gap-2 bg-neutral-0 rounded w-fit px-2 cursor-pointer border border-neutral-50 duration-300 ease-in-out",class:{
        'border border-bb-brand-purple bg-bb-secondary-purple':
          _vm.hoveredChip.value === chip.value && !_vm.hoveredChip.status,
        'border border-bb-error bg-bb-red-50': _vm.hoveredChip.value === chip.value && _vm.hoveredChip.status === 'close',
      },on:{"mouseenter":function($event){_vm.hoveredChip.value = chip.value},"mouseleave":function($event){_vm.hoveredChip.value = null
        _vm.hoveredChip.status = null}}},[_c('div',{staticClass:"flex flex-row gap-2 items-center"},[_c('p',{staticClass:"text-bb-text-default text-sm",class:{ 'max-w-40 truncate': chip.subLabel }},[_vm._v(" "+_vm._s(chip.label)+" ")]),(chip.subLabel)?_c('div',{staticClass:"bg-neutral-50 rounded-full px-2"},[_c('p',{staticClass:"text-bb-text-default text-xs"},[_vm._v(" "+_vm._s(chip.subLabel)+" ")])]):_vm._e()]),_c('div',{on:{"mouseenter":function($event){_vm.hoveredChip.status = 'close'},"mouseleave":function($event){_vm.hoveredChip.status = null}}},[_c('ic-cross',{attrs:{"size":"20"},on:{"close":function($event){return _vm.chips.splice(index, 1)}}})],1)])}),0):_c('div',{staticClass:"my-2 flex flex-wrap items-center gap-2"},_vm._l((_vm.chips),function(chip,index){return _c('div',{key:`${chip}-${index}`,staticClass:"flex items-center gap-2 bg-neutral-0 rounded-full w-fit px-2 cursor-pointer border border-neutral-50 duration-300 ease-in-out",class:{
        'border border-bb-brand-purple bg-bb-secondary-purple':
          _vm.hoveredChip.value === chip.value && !_vm.hoveredChip.status,
        'border border-bb-error bg-bb-red-50': _vm.hoveredChip.value === chip.value && _vm.hoveredChip.status === 'close',
      },on:{"mouseenter":function($event){_vm.hoveredChip.value = chip.value},"mouseleave":function($event){_vm.hoveredChip.value = null
        _vm.hoveredChip.status = null}}},[_vm._t("chip-label",function(){return [_c('p',{staticClass:"text-bb-text-default text-sm"},[_vm._v(" "+_vm._s(chip.label)+" ")])]},{"data":chip}),_c('div',{on:{"mouseenter":function($event){_vm.hoveredChip.status = 'close'},"mouseleave":function($event){_vm.hoveredChip.status = null}}},[_c('ic-cross',{attrs:{"size":"20"},on:{"close":function($event){return _vm.chips.splice(index, 1)}}})],1)],2)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }