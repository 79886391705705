import { render, staticRenderFns } from "./OrganizationRows.vue?vue&type=template&id=185a7738&scoped=true"
import script from "./OrganizationRows.vue?vue&type=script&lang=js"
export * from "./OrganizationRows.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185a7738",
  null
  
)

export default component.exports