import { render, staticRenderFns } from "./EditOrganizationNameModalForm.vue?vue&type=template&id=22578ca0&scoped=true"
import script from "./EditOrganizationNameModalForm.vue?vue&type=script&lang=js"
export * from "./EditOrganizationNameModalForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22578ca0",
  null
  
)

export default component.exports