<template>
  <div>
    <search-input
      class="w-full"
      dynamic-placeholder
      :disabled="disabled"
      :options-max-width="optionsMaxWidth"
      :error-message="errorMessage"
      :input-id="inputId"
      :input-name="inputName"
      :placeholder="placeholder"
      :options="options"
      :label="label"
      :sub-label="subLabel"
      :is-loading="isLoading"
      :grouped="grouped"
      @select-item="appendToChipList"
    >
      <!-- slot for icon from parent -->
      <template #label-icon>
        <slot name="icon" />
      </template>

      <template #label="{ label, inputId }">
        <div class="flex justify-between">
          <label
            class="font-medium"
            :for="inputId"
          >
            {{ label }}
          </label>
          <p
            v-if="chips.length > 0"
            class="font-medium text-bb-brand-purple text-sm cursor-pointer"
            @click="clearAllChips"
          >
            Clear All
          </p>
        </div>
      </template>
    </search-input>
    <div
      v-if="chipVariant == 'squared'"
      class="my-2 flex flex-wrap items-center gap-2"
    >
      <div
        v-for="(chip, index) in chips"
        :key="`${chip}-${index}`"
        class="flex items-center gap-2 bg-neutral-0 rounded w-fit px-2 cursor-pointer border border-neutral-50 duration-300 ease-in-out"
        :class="{
          'border border-bb-brand-purple bg-bb-secondary-purple':
            hoveredChip.value === chip.value && !hoveredChip.status,
          'border border-bb-error bg-bb-red-50': hoveredChip.value === chip.value && hoveredChip.status === 'close',
        }"
        @mouseenter="hoveredChip.value = chip.value"
        @mouseleave="
          hoveredChip.value = null
          hoveredChip.status = null
        "
      >
        <div class="flex flex-row gap-2 items-center">
          <p
            class="text-bb-text-default text-sm"
            :class="{ 'max-w-40 truncate': chip.subLabel }"
          >
            {{ chip.label }}
          </p>
          <div
            v-if="chip.subLabel"
            class="bg-neutral-50 rounded-full px-2"
          >
            <p class="text-bb-text-default text-xs">
              {{ chip.subLabel }}
            </p>
          </div>
        </div>
        <div
          @mouseenter="hoveredChip.status = 'close'"
          @mouseleave="hoveredChip.status = null"
        >
          <ic-cross
            size="20"
            @close="chips.splice(index, 1)"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="my-2 flex flex-wrap items-center gap-2"
    >
      <div
        v-for="(chip, index) in chips"
        :key="`${chip}-${index}`"
        class="flex items-center gap-2 bg-neutral-0 rounded-full w-fit px-2 cursor-pointer border border-neutral-50 duration-300 ease-in-out"
        :class="{
          'border border-bb-brand-purple bg-bb-secondary-purple':
            hoveredChip.value === chip.value && !hoveredChip.status,
          'border border-bb-error bg-bb-red-50': hoveredChip.value === chip.value && hoveredChip.status === 'close',
        }"
        @mouseenter="hoveredChip.value = chip.value"
        @mouseleave="
          hoveredChip.value = null
          hoveredChip.status = null
        "
      >
        <slot
          name="chip-label"
          :data="chip"
        >
          <p class="text-bb-text-default text-sm">
            {{ chip.label }}
          </p>
        </slot>
        <div
          @mouseenter="hoveredChip.status = 'close'"
          @mouseleave="hoveredChip.status = null"
        >
          <ic-cross
            size="20"
            @close="chips.splice(index, 1)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import IcCross from '@/components/icon/ic-cross.vue'

export default {
  name: 'MultiSelectSearchInput',
  components: { SearchInput, IcCross },
  props: {
    inputId: {
      type: [String, Number],
      required: true,
    },
    inputName: {
      type: [String, Number],
      default: 'default-input-name',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array, String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: null,
    },
    optionsMaxWidth: {
      type: String,
      default: 'max-w-64',
    },
    dynamicPlaceholder: {
      type: Boolean,
      default: false,
    },
    optionsSuffix: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    chipVariant: {
      type: String,
      default: 'default',
    },
    grouped: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chips: [],
      hoveredChip: { value: null, status: null },
    }
  },
  methods: {
    appendToChipList(item) {
      if (this.chips.includes(item)) return
      this.chips.push(item)
      this.updateChips()
    },
    clearAllChips() {
      this.chips = []
      this.updateChips()
    },
    updateChips() {
      this.$emit('update-chips', this.chips)
    },
  },
}
</script>

<style scoped lang="scss"></style>
