<template>
  <div>
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Updating organization name...</p>
        </brightbid-loader>
      </div>
    </div>
    <div class="m-10">
      <div class="text-center">
        <h3 class="h3">Edit Organization</h3>
      </div>
      <div class="mt-6">
        <text-input
          v-model="organizationName"
          :disabled="isLoading"
          label="Name"
          input-name="organization-name"
          input-id="organization-name"
          input-type="text"
          placeholder="Organization name"
          :error-message="$v.organizationName.$error ? 'Please enter an Organization name' : null"
          @focusout="$v.organizationName.$touch"
        />
      </div>
    </div>
    <!--SAVE AND CANCEL BUTTONS-->
    <div class="flex justify-between items-center m-10">
      <merge-button-round
        :disabled="isLoading"
        button-type="secondary"
        class="w-40"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        :disabled="isLoading"
        button-type="primary"
        class="w-40"
        @click="save"
      >
        <p>Save</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import TextInput from '@/components/input/brightbid/TextInput'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

export default {
  name: 'EditOrganizationNameModalForm',
  components: {
    TextInput,
    BrightbidLoader,
  },
  props: {
    selectedOrganization: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      organizationName: null,
    }
  },
  mounted() {
    this.organizationName = this.selectedOrganization.organization
  },
  validations() {
    return {
      organizationName: { required },
    }
  },
  methods: {
    save() {
      this.$v.organizationName.$touch()
      if (this.$v.organizationName.$invalid) return
      this.$emit('save', this.organizationName)
    },
  },
}
</script>

<style scoped></style>
